import Hammer from '@bower_components/hammerjs';

export default {
  haltEventListeners: [
    'touchstart',
    'touchend',
    'touchmove',
    'touchleave',
    'touchcancel'
  ],
  hammer: null,
  init: function(options) {
    var instance = options.instance;
    var initialScale = 1;
    var pannedX = 0;
    var pannedY = 0;

    var inputclass = (Hammer.SUPPORT_POINTER_EVENTS) ?
      Hammer.PointerEventInput : Hammer.TouchInput;
    // Init Hammer
    // Listen only for pointer and touch events
    this.hammer = new Hammer(options.svgElement, {inputClass: inputclass});

    // Enable pinch
    this.hammer.get('pinch').set({enable: true});

    // Handle double tap
    this.hammer.on('doubletap', () => {
      instance.zoomIn();
    });

    // Handle pan
    this.hammer.on('panstart panmove', ev => {
      // On pan start reset panned variables
      if (ev.type === 'panstart') {
        pannedX = 0;
        pannedY = 0;
      }

      // Pan only the difference
      instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY});
      pannedX = ev.deltaX;
      pannedY = ev.deltaY;
    });

    // Handle pinch
    this.hammer.on('pinchstart pinchmove', ev => {
      // On pinch start remember initial zoom
      if (ev.type === 'pinchstart') {
        initialScale = instance.getZoom();
        instance.zoom(initialScale * ev.scale);
      }

      instance.zoom(initialScale * ev.scale);
    });

    // Prevent moving the page on some devices when panning over SVG
    options.svgElement.addEventListener('touchmove', ev => {
      ev.preventDefault();
    });
  },
  destroy: function() {
    this.hammer.destroy();
  }
};
