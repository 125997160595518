import $ from 'jquery';


class Printer {
  constructor(panzoomObj, resizer, onBeforePrint) {
    this.printModal = $('#PrintModal');
    this.successButton = $('#PrintStart');

    this.resizer = resizer;
    this._panzoom = panzoomObj;

    this.onBeforePrint = onBeforePrint;
  }

  set panzoom(obj) {
    this._panzoom = obj;
  }

  on() {
    this.printModal.on('show.bs.modal', () => {
      this.beforePrint();
      setTimeout(() => this.printReady(this), 800);
    });
    this.printModal.on('hide.bs.modal', () => this.disablePrint(this));

    this.successButton.click(() => {
      setTimeout(window.print, 200);
    });

    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener(mql => {
        if (mql.matches) {
          this.beforePrint(this);
        }
      });
    }
    window.onbeforeprint = this.beforePrint;
  }

  /**
   * disable print button and resets plan to normal state
   */
  disablePrint() {
    this.successButton.attr('disabled', true);
    this.successButton.find('span').addClass('loading-ring').removeClass(
      'glyphicon glyphicon-print');
    $(window).resize(this.resizer);
    setTimeout(this.resizer, 10);
  }

  /**
   * called when print is ready, activates the print button
   */
  printReady() {
    this.successButton.find('> span').removeClass('loading-ring').addClass(
      'glyphicon glyphicon-print');
    this.successButton.attr('disabled', false);
  }

  /**
   * performs action to make plan print ready
   */
  beforePrint() {
    var map = $('#Map');
    if (this._panzoom && map.is('.display')) {
      if (this.onBeforePrint) {
        this.onBeforePrint();
      }
      $(window).off('resize', this.resizer);
      map.attr({height: '170mm', width: '277mm'}).removeClass('display');
      this._panzoom.resize();
      this._panzoom.fit();
      this._panzoom.center();
    }
  }
}

export default Printer;
