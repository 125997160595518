import jQuery from 'jquery';
import moment from 'moment';
import 'moment/locale/de';

const defaultFontSize = 280;
const edgeTolerance = 0.05;

// selectors
const selectPath = 'path:not([class="BoundingBox"])';
const selectTextGroup = 'g[class="com.sun.star.drawing.TextShape"]';
const selectRoomNrBox = 'g[class="com.sun.star.drawing.ClosedBezierShape"]';
const selectUngroupText = 'text[class="TextShape"]';
const selectText = 'text';
const selectTspan = 'tspan';

// selector funcs
const selectRoom = id => `g[id="${id}"]`;

// helpers
const svgTranslate = (x, y) => `translate(${x},${y})`;

/**
 * check if two svg objects collide
 * @param  {SVGNode} n1 parent node
 * @param  {SVGNode} n2 moveable child
 * @return {bool}    true if colliding
 */
function edgeDistances(n1, n2) {
  let bb1 = n1.getBBox();
  let bb2 = n2.getBBox();
  let toleranceW = bb1.w * edgeTolerance;
  let toleranceH = bb1.h * edgeTolerance;
  return {
    left: (bb2.x - toleranceW - bb1.x),
    right: (bb1.x2 - toleranceW - bb2.x2),
    top: (bb2.y - toleranceH - bb1.y),
    bottom: (bb1.y2 - toleranceH - bb2.y2)
  };
}

/**
 * holds ref to snapsvg object performs changes on it, base of room data
 */
class RoomNode {
  constructor(room, data) {
    this.room = room;
    this.data = data;
    this.path = this.room.select(selectPath);
    this.text = null;
    this.initialTextPos = null;
    this.pos = null;
    this.fontSize = defaultFontSize;
    this.reposLimit = 100;
    this.scale = 1;
  }

  /**
   * paints its path node, base on coloringFunc
   * @param  {function} coloringFunc coloring function
   */
  paint(coloringFunc) {
    if (this.path) {
      this.path.attr({fill: coloringFunc(this.data)});
    }
  }

  /**
   * create text nodes with room data in it
   * @param  {string} color    desired text color
   * @param  {int} fontSize override font size
   * @param  {function} placeOtherTexts override text func
   * @return {boolean} result
   */
  drawText(color, fontSize, placeOtherTexts) {
    if (this.data.svgid === 'COMBO') {
      fontSize = '12px';
    }
    // try get some old props
    this.analyseOldText();

    var g = this.room.g();
    let localFontSize = fontSize || this.fontSize;
    // attribute helper, because lazy
    let setTextAttr = t => {
      t.attr({'font-size': localFontSize, 'fill': color});
    };

    // remove all characters behind first komma
    let name = this.data.name.replace(/[,/].+/g, '');
    var nameText = this.room.text(0, 0, name);
    setTextAttr(nameText);
    // add text to group
    g.add(nameText);

    // get bbox to set next text properly under this one
    let nameBBox = nameText.getBBox();

    var additionalTextplacer = placeOtherTexts ||
      function(node, g, nameBBox, setTextAttr) {
        if (node.data.number &&
            node.data.name.search(/[,/]/g) !== -1 &&
            name !== node.data.number) {
          // only create number text if name has a komma name is not the same as number
          let nr = node.data.number;
          let nrText = node.room.text(0, nameBBox.h, nr);
          setTextAttr(nrText);
          // add text to group
          g.add(nrText);
        }

        let nextHeight;
        if (node.data.area && !node.data.unavailable) {
          // only create area text if room is free and there is a area
          nextHeight = nameBBox.h * 2;
          let areaText = node.room.text(0, nextHeight,
            Number(node.data.area).toLocaleString() + ' m²');
          setTextAttr(areaText);
          // add text to group
          g.add(areaText);
        }

        if (node.data.soon_free) {
          // only create area text if room is free and there is a area
          nextHeight = (nextHeight) ? nameBBox.h * 3 : nameBBox.h * 2;
          let dateText = node.room.text(0, nextHeight,
            'frei ab: ' + moment(node.data.soon_free).format('DD.MM.YY'));
          setTextAttr(dateText);
          dateText.attr({'font-size': localFontSize * 0.8});
          // add text to group
          g.add(dateText);
        }
      };

    additionalTextplacer(this, g, nameBBox, setTextAttr, localFontSize);

    // make group a property
    this.text = g;
    this.pos = this.initialTextPos;
    this.transformText();

    return true;
  }

  /**
   * search for existing text nodes, extract properties
   */
  analyseOldText() {
    // all old texts
    let oldTextNodes = this.room.selectAll(selectTextGroup);
    if (oldTextNodes.length) {
      // only care about first one
      let bbox = oldTextNodes[0].select(selectText).getBBox();
      // extract pos, and font size
      this.initialTextPos = {x: bbox.x, y: bbox.y};
      this.fontSize = oldTextNodes[0].select(
        selectTspan).node.getAttribute('font-size') * 1.2;
    } else {
      // no old text, lets pos text near left upper edge of room
      let roomBBox = this.path.getBBox();
      this.initialTextPos = {x: roomBBox.w * 0.20 + roomBBox.x,
        y: roomBBox.h * 0.12 + roomBBox.y};
    }
  }

  // /**
  //  * return the position of the text node
  //  * @return {object} pos object
  //  */
  // get textPos() {
  //   if (!this.text) {
  //     // no text group, so return intial values
  //     if (!this.initialTextPos) {
  //       this.analyseOldText();
  //     }
  //     return this.initialTextPos;
  //   }
  //   let bbox = this.text.getBBox();
  //   return {x: bbox.x, y: bbox.y};
  // }

  /**
   * translate the text node around
   * @param  {Object} pos position object
   */
  transformText(pos) {
    this.pos = pos || this.pos;
    if (this.text) {
      this.text.attr({
        transform: `${svgTranslate(this.pos.x, this.pos.y)}
         scale(${this.scale})`
      });
    }
  }

  /**
   * find a none colliding text pos
   */
  repositionText() {
    // if (this.data.number != '161') {
    //   return ;
    // }
    let brokens = this.getNewBounds();
    let test = 0;
    while (!jQuery.isEmptyObject(brokens) && test < this.reposLimit) {
      // do some scaling and translating, until if fits
      let pos = this.pos;
      if (brokens.scale) {
        this.scale -= brokens.scale;
      } else {
        if (brokens.x) {
          pos.x += brokens.x; // - innerPos.x;
        }
        if (brokens.y) {
          pos.y += brokens.y; // - innerPos.y;
        }
      }
      this.transformText(pos);

      test++;
      brokens = this.getNewBounds();
    }
  }

  isCombo() {
    return this.data.svgid === 'COMBO';
  }

  /**
   * return bounds which are colliding
   * @param {Object} bounds bound object
   * @return {Object} colliding bounds
   */
  getNewBounds() {
    let bounds = edgeDistances(this.path, this.text);
    if ((bounds.left <= 0 && bounds.right <= 0) ||
        (bounds.top <= 0 && bounds.bottom <= 0)) {
      return {scale: 0.05};
    }

    let result = {};

    let leftAbs = Math.abs(bounds.left);
    let rightAbs = Math.abs(bounds.right);
    let topAbs = Math.abs(bounds.top);
    let bottomAbs = Math.abs(bounds.bottom);

    if ((bounds.left <= 0 && leftAbs > rightAbs) ||
        (bounds.right <= 0 && rightAbs > leftAbs)) {
      return {scale: 0.05};
    }

    if ((bounds.top <= 0 && topAbs > bottomAbs) ||
        (bounds.bottom <= 0 && bottomAbs > topAbs)) {
      return {scale: 0.05};
    }

    if (bounds.left < 0) {
      result.x = -bounds.left;
    }

    if (bounds.right < 0) {
      result.x = bounds.right;
    }

    if (bounds.top < 0) {
      result.y = -bounds.top;
    }

    if (bounds.bottom < 0) {
      result.y = bounds.bottom;
    }

    return result;
  }
}

/**
 * holds a list of all rooms and a ref to the svg plan
 */
class Plan {
  constructor(selector, floor, building, location) {
    this.s = selector;
    this.floor = floor;
    this.building = building;
    this.location = location;

    this.rooms = [];
    this.setRooms();
  }

  /**
   * populates the rooms array
   */
  setRooms() {
    this.floor.rooms.forEach(r => {
      let node = this.s.select(selectRoom(r.svgid));
      if (node) {
        this.rooms.push(new RoomNode(node, r));
      } else {
        console.log([r.svgid, node]);
      }
    });
  }

  /**
   * paints all rooms
   * @param  {functions} coloringFunc get rooms color
   */
  paintRooms(coloringFunc) {
    this.rooms.forEach(r => {
      r.paint(coloringFunc);
    });
  }

  /**
   * displays text in all rooms
   * @param  {string} textColor text color
   * @param  {int} fontSize  override font size
   * @param  {function} placeOtherTexts  override text func
   */
  drawTexts(textColor, fontSize, placeOtherTexts) {
    this.rooms.forEach(r => {
      var done = r.drawText(textColor, fontSize, placeOtherTexts);
      if (done && !r.isCombo()) { // combo room may have text outside bbox
        r.repositionText();
      }
    });
  }

  /**
   * removes all unwanted node from the plan
   */
  removeUnwantedNode() {
    this.s.selectAll(selectTextGroup).remove();
    this.s.selectAll(selectRoomNrBox).remove();
    this.s.selectAll(selectUngroupText).remove();
  }
}

export default Plan;
