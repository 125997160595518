import '../styles/main.scss';

import $ from 'jquery';
import Emitter from 'es6-emitter'
const jQuery = $;
window.jQuery = jQuery;
import '@bower_components/Snap.svg';
import svgPanZoom from '@bower_components/svg-pan-zoom';
require('bootstrap');
import 'bootstrap-select';
import '@bower_components/bootstrap-switch';
import {Router} from '@bower_components/director/build/director';
import Slideout from '@bower_components/slideout.js';

import Plan from './plans/plan.js';
import eventsHandler from './plans/hammer_handler.js';
import Printer from './plans/print.js';

// runtime variables
var panZoom;
var container = $('.map-container');
var slideout;
var print;
var s;

/** set height and width on the map */
function setBoundings() {
  container = $('.map-container');
  var width = container.width();
  var offsetTop = container.offset().top;
  var height = $(window).height();

  if (offsetTop < height) {
    height -= container.offset().top;
  }

  $('#Map').attr({height: height, width: width}).attr('class', 'display');
}

// helper functions
/** resize the svg object and fits the plan in it */
function resizePlan() {
  setBoundings();
  if (panZoom) {
    panZoom.resize();
    panZoom.fit();
    panZoom.center();
  }
}

($ => {
  'use strict';

  s = new Snap('#Map');
  var locations = $('#LocationSwitch');
  var buildings = $('#BuildingSwitch');
  var floors = $('#FloorSwitch');
  var data = null;
  var title;
  // var panZoom;
  $.fn.selectpicker.Constructor.BootstrapVersion = 3;
  $('.selectpicker').selectpicker(
    {noneSelectedText: 'Keine Auswahl verfügbar'});

  var roomColors = {
    unavailable: '#ccc',
    occupied: '#ff6666',
    reserved: '#f79fff',
    terminated: '#f3fd58',
    free: '#1ada55',
    seminar: '#b1fbff',
    text: '#111'
  };

  var router = new Router().configure({html5history: false});
  router.param('slugid', /([\w-\d]+)/);

  router.configure({
    recurse: 'forward',
    after: function() {
      router.lastHash = router.currentHash;
      router.currentHash = window.location.hash.substr(1);
    }
  });

  router.mount({
    '/': {
      before: function() {
        console.log(router.lastHash);
        console.log(router.currentHash);
        if (router.currentHash === '/') {
          window.history.go(-2);
          console.log('back');
          return false;
        }
      }
    }
  });

  $(document).ready(() => {
    setBoundings();
    loadJson('/api/objects/locations/');

    slideout = new Slideout({
      panel: document.getElementById('panel'),
      menu: document.getElementById('menu'),
      padding: 256,
      tolerance: 70,
      touch: false,
      duration: 500
    });
    print = new Printer(panZoom, resizePlan, () => {
      slideout.close();
    });
    print.on();
    // Toggle button
    $('.toggle-button').on('click', () => {
      slideout.toggle();
    });

    $('#collapseHeader').on('shown.bs.collapse hidden.bs.collapse', () => {
      resizePlan();
    });

    $('#collapseHeader').on('shown.bs.collapse', () => {
      $('.show-title i').attr('class', 'glyphicon glyphicon-chevron-up');
    });

    $('#collapseHeader').on('hidden.bs.collapse', () => {
      $('.show-title i').attr('class', 'glyphicon glyphicon-chevron-down');
    });
  });

  $(window).resize(resizePlan);

  // selects the option tag where its data(dataName).slug == slug
  /**
   * return data of selected option or false
   * @param  {jQuerySelector} selection - the <select> to look in
   * @param  {string} slug - slug of the item to find
   * @param  {string} dataName - name of the data store
   * @return {mixed} the matching data or false
   */
  function selectBySlug(selection, slug, dataName) {
    var madeSelection = false;
    selection.find('option').each((i, el) => {
      var d = $(el).data(dataName);
      if (d.slug === slug) {
        $(el).attr('selected', 'selected');
        madeSelection = d;
        // break loop
        return false;
      }
    });
    return madeSelection;
  }

  // resets all option tags inside the selection
  /**
   * save each item of the data array inside data(dataName)
   * @param  {jQuerySelector} selection <select> to populate
   * @param  {[type]} dataArray array of data objects
   * @param  {[type]} dataName  name with which data is stored
   */
  function populateSelection(selection, dataArray, dataName) {
    selection.empty();


    $.each(dataArray, (i, el) => {
      selection.append($('<option />').val(el.id).text(el.name));
      selection.find('option:last()').data(dataName, el);

      if (el.pos === 0) {
        selection.find('option:last()').attr('selected', 'selected');
      }
    });
  }

  /**
   * creates a uri of *.slug from selected data objects
   * @return {string} uri
   */
  function getUrl() {
    var location = locations.find(':selected').data('location');
    var building = buildings.find(':selected').data('building');
    var floor = floors.find(':selected').data('floor');
    return location.slug + '/' + building.slug + '/' + floor.slug;
  }

  /**
   * entry point into app, load all locations from server
   * @param  {string} path json url
   */
  function loadJson(path) {
    jQuery.getJSON(path, result => {
      data = result.results;
      populateSelection(locations, data, 'location');
      locations.trigger('change');
    });
  }
  // event listeners
  container.on('plan-loaded', event => {
    $(event.currentTarget).find('#Map').removeClass('loading');
    $('.title > span').html(title);
    resizePlan();
    setTimeout(() => {
      $(window).trigger('resize');
    }, 1000);
    // slideout.close();
  });

  container.on('plan-loading', event => {
    let elem = $(event.currentTarget);
    elem.find('#Map').addClass('loading');
    $('.title > span').text('Plan wird geladen ...');
  });

  locations.on('change', event => {
    let elem = $(event.currentTarget);
    var location = elem.find(':selected').data('location');
    populateSelection(buildings, location.buildings, 'building');

    buildings.trigger('change');
  });

  buildings.on('change', event => {
    let elem = $(event.currentTarget);
    var building = elem.find(':selected').data('building');
    populateSelection(floors, building.floors, 'floor');

    floors.trigger('change');
  });

  floors.one('change', () => {
    var route = router.getRoute();
    // monkey patch, index route is not called, when route is empty
    if (route[0] === '') {
      route = '/';
    }
    router.init(route);

    floors.on('change', () => {
      router.setRoute(getUrl());
    });
  });

  router.on('/$', () => {
    router.setRoute(getUrl());
  });

  router.on('/:slugid/:slugid/:slugid',
  (locationSlug, buildingSlug, floorSlug) => {
    var selectedLocation = selectBySlug(
      locations, locationSlug, 'location') || data[0];

    populateSelection(buildings, selectedLocation.buildings, 'building');
    var selectedBuilding = selectBySlug(
      buildings, buildingSlug, 'building') || selectedLocation.buildings[0];

    populateSelection(floors, selectedBuilding.floors, 'floor');
    var selectedFloor = selectBySlug(
      floors, floorSlug, 'floor') || selectedBuilding.floors[0];

    container.trigger('plan-loading');
    $('.selectpicker').selectpicker('refresh');
    title = selectedLocation.name + ' | ' +
      (selectedBuilding.name ? selectedBuilding.name + ' | ' : '') +
      selectedFloor.name;

    loadSVG(selectedFloor, selectedBuilding, selectedLocation);
  });

  //
  // svg manipulations
  //

  /**
   * loads the svg file from server and replaces the loaded one
   * @param  {object} floor    contains the selected floor obj
   * @param  {object} building selected building
   * @param  {object} location selected location
   */
  function loadSVG(floor, building, location) {
    s.clear();
    Snap.load('/plans/' + floor.plan, f => {
      var g = f.select('g[class="SlideGroup Slide Page"]');
      s.append(g);

      // renderSVG(floor, building, location);
      var plan = new Plan(s, floor, building, location);
      plan.paintRooms(getColor);
      var fontSize = 320;
      if (location.id === 3) {
        fontSize = 500;
      }

      if (location.id === 4) {
        fontSize = 700;
      }

      plan.drawTexts(roomColors.text, fontSize);
      plan.removeUnwantedNode();

      // recreate panzoom, because the slidegroup has been overwritten
      if (panZoom) {
        panZoom.destroy();
      }
      panZoom = svgPanZoom('#Map', {
        viewportSelector: '.SlideGroup',
        customEventsHandler: eventsHandler
      });

      print.panzoom = panZoom;

      // publish loaded
      container.trigger('plan-loaded');
    });
  }

  /**
   * assign a color to a room
   * @param  {object} room selected room object
   * @return {string}      color
   */
  function getColor(room) {
    var color = '#fff';
    if (room.free) {
      color = roomColors.free;
    } else {
      color = roomColors.occupied;
      if (room.soon_free) {
        color = roomColors.terminated;
      }
    }

    if (room.unavailable) {
      color = roomColors.unavailable;
    }

    if (room.is_seminar_room) {
      color = roomColors.seminar;
    }

    if (room.reserved) {
    // if (parseInt(room.occupancy, 10) === 3) {
      color = roomColors.reserved;
    }
    return color;
  }
})(jQuery);
